export const MainNav = [
    {
        icon: 'lnr-chart-bars',
        label: 'Dashboard',
        to: '/dashboard',
    },
    {
        icon: 'pe-7s-notebook',
        label: 'New Launches',
        to: '/policy-ad',
    },
    {
        icon: 'pe-7s-notebook',
        label: 'My Policies',
        to: '/policy-document',
    },
    {
        icon: 'lnr-film-play',
        label: 'Videos Ads',
        to: '/promo-video',
    },
    {
        icon: 'lnr-laptop-phone',
        label: 'My Meetings',
        to: '/conferences',
    },
    {
        icon: 'lnr-envelope',
        label: 'General Notices',
        to: '/news-letter',
    },
    {
        icon: 'lnr-cog',
        label: 'Submit A Query',
        to: '/feedback',
    },
    {
        icon: 'pe-7s-note2',
        label: 'Profile',
        to: '/settings',
    }
];
